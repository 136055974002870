/* CSS for isf sivilsamfunn */
@import "utils.less";

#head-wrapper,
#globalnav,
#footer-wrapper {
  background-color: @color-sivilsamfunn !important;
}

a {
  color: @color-link-sivilsamfunn;
}
.backgroundOnFocusHoverUnderlinedText(@color-link-sivilsamfunn);

.add-comment-header,
#comments-header-left a,
#main *:not(.vrtx-messages) > .vrtx-resource a.vrtx-title,
.vrtx-search-results-container div.vrtx-search-results .result h2 a,
.grid-container .vrtx-frontpage-box h2 a {
  color: @color-link-sivilsamfunn;
  &:hover,
  &:focus {
    color: @color-link-sivilsamfunn;
  }
}

.not-for-ansatte {
  .vrtx-image-listing-include,
  #head-wrapper #head {
    #header {
      a#logo {
        .font-size(32);
        font-weight: 400;
      }
    }
  }

  #head-wrapper {
    #head {
      #header {
        a#logo {
          top: 72px;
          color: @color-sivilsamfunn-header-footer-text;
          &:hover,
          &:focus {
            background-color: @color-light;
          }
        }
        .header-samarbeid {
          color: @color-sivilsamfunn-header-footer-text;
          a {
            color: @color-sivilsamfunn-header-footer-text;
            border-bottom: 1px solid @color-sivilsamfunn-header-footer-text;
            &:hover,
            &:focus {
              background-color: @color-light;
            }
          }
        }
      }
      .header-search {
        .header-search-expand {
          color: @color-sivilsamfunn-header-footer-text;
          font-weight: 400;
        }
      }
      .head-menu {
        font-weight: 400;
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link-sivilsamfunn !important;
        }
        a {
          color: @color-sivilsamfunn-header-footer-text;
          &:hover,
          &:focus {
            background-color: @color-light;
          }
        }

        .vrtx-login-manage-component .vrtx-dropdown-link {
          background: transparent
            url("../uio1/images/head-dropdown-toggle-black.png")
            no-repeat scroll 100% 53%;
        }
      }
      .header-search a.header-search-expand {
        background-color: @color-sivilsamfunn !important;
      }
    }
  }

  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red)
    input[type="button"]:not(.red):not([class^="admin-frontpage"]),
  input[type="submit"]:not(.submit-comment-button):not(.red):not([class^="admin-frontpage"]),
  input[type="cancel"]:not(.red),
  .header-search-expand,
  button:not([class^="admin-frontpage"]):not([class^="change-positions-in-row"]) {
    height: 52px;
    background-color: @color-sivilsamfunn--dark !important;
    &:focus,
    &:hover {
      background-color: @color-neutral !important;
    }
  }
  &.header-search-collapsable .header-search-expand {
    background: #737677
      url("../images/magnifier-samfunn.svg") no-repeat
      0px 17px;
    background-size: 15px auto;
  }
}
button.search-submit,
not-for-ansatte
  button:hover:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search) {
  background-color: @color-sivilsamfunn--dark !important;
}

#main {
  .navigation-links li a {
    &:after {
      background: transparent
        url("../images/arrow-forward-blue.svg")
        no-repeat scroll 0 0 / auto 100%;
    }
    &.active {
      color: @color-light;
      background-color: @color-sivilsamfunn--dark !important;

      &:after {
        background-image: url(../images/arrow-forward-white.svg);
      }
    }
    &:hover,
    &:focus {
      color: @color-light;
      background-color: @color-sivilsamfunn--dark !important;
    }
  }

  .button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: @color-sivilsamfunn--dark !important;
    font-weight: 400;
    &:hover,
    &:focus {
      background-color: @color-neutral !important;
    }
  }
}

.ui-tabs.ui-widget.ui-widget-content {
  .ui-tabs-nav li {
    &.ui-state-active,
    &.ui-state-hover {
      background: @color-sivilsamfunn--dark;
    }
  }
}

#head-wrapper #globalnav ul li {
  a,
  a:hover,
  a:focus,
  &.vrtx-active-item a,
  &.vrtx-active-item a:hover,
  &.vrtx-active-item a:focus {
    background-color: @color-sivilsamfunn;
    color: @color-sivilsamfunn-header-footer-text !important;
    font-weight: 400;
  }
}

.ui-tabs.ui-widget.ui-widget-content p.vrtx-more-external-publications a,
#vrtx-listing-completed-ongoing a {
  background-image: url("../images/arrow-forward-blue-bullet.svg") !important;
  &:focus {
    background-color: @color-neutral !important;
  }
}
a.vrtx-more,
div.vrtx-more a,
a.all-messages,
a.all-comments,
.footer > .vrtx-search-scope a,
a.more-url {
  &:before {
    background-image: url("../images/arrow-forward-blue-bullet.svg") !important;
  }
}

.not-for-ansatte #footer-wrapper {
  .footer-logo img {
    max-width: 500px;
    height: fit-content;
    width: fit-content;
    margin-top: -40px;
  }
  .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
    background: rgba(0, 0, 0, 0)
      url("../uio1/images/dropdown-black.png")
      no-repeat scroll right center / 22px auto;
    &:focus,
    &:hover {
      background-image: url("../uio1/images/dropdown-black-hover.png");
    }
  }
  .col-3-5, .col-2-5 {
    padding-top: 40px;
  }
  #footers {
    *,
    a {
      color: @color-sivilsamfunn-header-footer-text;
    }
  }
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background-image: url("../images/icon-chevron-right-light-blue.svg");
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-light-blue.svg");
  }
}

.js .toggle-more {
  & > {
    h2:before,
    h2:before,
    h3:before,
    h4:before,
    h5:before,
    h6:before {
      background-image: url("../images/icon-chevron-right-light-blue.svg");
    }
  }

  &.toggle-more-active > {
    h2:before,
    h3:before,
    h4:before,
    h5:before,
    h6:before {
      background-image: url("../images/icon-chevron-down-light-blue.svg");
    }
  }
}

.footer-bottom-row {
  background-color: @color-sivilsamfunn-footer-bottom;
  p {
    color: @color-sivilsamfunn-header-footer-text;
    a {
      &.footer-logo-isf {
        margin-right: 39px;
        line-height: 0;
        img {
          height: 49px;
          width: 97px;
        }
      }
      &.footer-logo-norce {
        line-height: 0;
        img {
          height: 35px;
          width: 123px;
        }
      }
      &:focus {
        background-color: @color-light;
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #head-wrapper {
      background: none @color-sivilsamfunn;
      #head {
        #header {
          a#logo {
            line-height: 1.22;
          }
          .header-samarbeid {
            position: static;
            margin: 10px 0 5px 0;
            .font-size(16);
          }
        }
      }
    }
    #menu-wrapper .menu {
      li {
        background-color: @color-sivilsamfunn !important;

        &.active {
          background-color: darken(@color-sivilsamfunn, 4%) !important;
        }
        a {
          color: @color-sivilsamfunn-header-footer-text !important;
          &.toggle-navigation .icon {
            background-image: url("../images/nav-icon-sivilsamfunn.svg") !important;
          }
          &.toggle-search .icon {
            background-image: url("../images/search-icon-sivilsamfunn.svg") !important;
          }
        }
      }
    }
    #main {
      .navigation-links li {
        a {
          background: transparent
            url("../images/arrow-forward-blue.svg")
            no-repeat scroll 3px 4px / auto 100%;
          background-size: 16px 16px !important;
          &:after {
            background: none !important;
          }
          &:hover,
          &:focus {
            background-color: transparent !important;
            color: @color-link-sivilsamfunn !important;
            text-decoration: underline;
          }
        }
      }
    }

    #footer-wrapper {
      .footer-logo {
        max-height: 60px;

        img {
          max-width: 100% !important;
        }
      }
    }
  }
}

@media print {
  .not-for-ansatte #head-wrapper {
    background: @color-sivilsamfunn !important;
  }
}
