.centerGrid() {
  width: @fullWidth;
  margin: 0 auto;
}

.resetClearfix() {
  display: block;
  clear: none;
}

.floatfix() {
  float: left;
  width: 100%;
  clear: left;
}

.georgia() {
  font-family: Georgia, "Bitstream Charter", "Charis SIL", Utopia, "URW Bookman L", serif;
}

.breakWord() {
  word-wrap: break-word;
  word-break: normal;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.backgroundOnFocusHoverUnderlinedText(@bgColor) {
  body:not(#vrtx-frontpage) p.elm-has-own-text a:not([class*='button']),
  body:not(#vrtx-frontpage) span.elm-has-own-text a:not([class*='button']),
  body:not(#vrtx-frontpage) i.elm-has-own-text a:not([class*='button']),
  body:not(#vrtx-frontpage) em.elm-has-own-text a:not([class*='button']),
  body:not(#vrtx-frontpage) bold.elm-has-own-text a:not([class*='button']),
  body:not(#vrtx-frontpage) strong.elm-has-own-text a:not([class*='button']),
  body:not(#vrtx-frontpage) td.elm-has-own-text:not([class*='person-listing']) a:not([class*='button']),
  body:not(#vrtx-frontpage) ul:not([class^='vrtx-tags-']) li.elm-has-own-text > a:not([class*='button']),
  body:not(#vrtx-frontpage) ul:not([class^='vrtx-tags-']) li.elm-has-own-text > *:not(ul):not(ol) a:not([class*='button']),
  body:not(#vrtx-frontpage) ol:not([class^='vrtx-tags-']) li.elm-has-own-text > a:not([class*='button']),
  body:not(#vrtx-frontpage) ol:not([class^='vrtx-tags-']) li.elm-has-own-text > *:not(ul):not(ol) a:not([class*='button']) {
    &:focus,
    &:hover,
    &.hover {
      background-color: @bgColor;
      .boxShadow(0 0 0 2px @bgColor);
    }
  }
}

.backgroundOnFocusHoverFooterUnderlinedText(@bgColor, @color: #fff) {
  #footer-wrapper #footers a:not(.vrtx-dropdown-form-link):not(.vrtx-dropdown-link) {
    &:focus,
    &:hover {
      background-color: @bgColor;
      .boxShadow(0 0 0 2px @bgColor);
      color: @color;
    }
  }
}

.mainButtonsBg(@bgColor, @bgColorHover) {
  #main {
    .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
    input[type="button"]:not(.red),
    input[type="submit"]:not(.submit-comment-button):not(.red),
    input[type="cancel"]:not(.red),
    button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white) {
      background-color: @bgColor;

      &:hover,
      &:focus {
        background-color: @bgColorHover;
      }
    }
  }
}

.mainButtonsFontColor(@color, @colorHover) {
  #main {
    .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
    input[type="button"]:not(.red),
    input[type="submit"]:not(.submit-comment-button):not(.red),
    input[type="cancel"]:not(.red),
    button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white) {
      color: @color;

      &:hover,
      &:focus {
        color: @colorHover;
      }
    }
  }
}

.redButtonsBg(@color, @colorHover) {
  .button-large-blue.red, .button-large.red, .button.red, .feedback-no.red, .feedback-yes.red, .ui-dialog.ui-widget button.red, .vrtx-button-larger:not(form).red, .vrtx-button:not(form).red, button.red, input[type=button].red, input[type=submit].red, input[type=reset].red {
    background-color: @color;

    &:hover,
    &:focus {
      background-color:  @colorHover;
    }
  }
}

.redButtonsFontColor(@color, @colorHover) {
  .button-large-blue.red, .button-large.red, .button.red, .feedback-no.red, .feedback-yes.red, .ui-dialog.ui-widget button.red, .vrtx-button-larger:not(form).red, .vrtx-button:not(form).red, button.red, input[type=button].red, input[type=submit].red, input[type=reset].red {
    color: @color;

    &:hover,
    &:focus {
      color: @colorHover;
    }
  }
}
