/*
 * Utilities
 *
 */

@import 'variables.less';
@import 'mixins.less';
@import 'animations.less';

.ui-helper-hidden-accessible,
.offscreen-screenreader {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

table.offscreen-screenreader {
  display: block;
}
